/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import HeaderMobile from './header_mobile';
import Footer from './footer';
import HeaderDesktop from './header_desktop';

const Layout: FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  return (
    <div>
      {isMobile ? (
        <div>
          <HeaderMobile />
          <div style={{ paddingTop: '15vh' }}>{children}</div>
        </div>
      ) : (
        <div className="pb-6">
          <HeaderDesktop />
          <div style={{ paddingTop: '5vh' }}>{children}</div>
        </div>
      )}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
