import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import '../styles/navbar.scss';

const HeaderDesktop: FC = () => {
  const [menuIsDisplayed, setMenuIsDisplayed] = useState<boolean | undefined>(
    false,
  );
  const handleClick = () => setMenuIsDisplayed(!menuIsDisplayed);
  return (
    <nav
      className="navbar has-navbar-fixed-top has-shadow mb-6"
      role="navigation"
      aria-label="main navigation"
      style={{
        height: '100px',
        position: 'fixed',
        width: '100%',
        top: '0',
        marginBottom: '200px',
      }}
    >
      <div className="navbar-brand">
        <Link
          to="/"
          style={{
            left: '50%',
            height: '20%',
            width: '5%',
          }}
          className="brand"
        >
          <figure>
            <img className="image" src="../../aisoul.png" alt="logo" />
          </figure>
        </Link>
        <div
          role="button"
          className={`navbar-burger burger${
            menuIsDisplayed ? 'is-active' : ''
          } `}
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={`navbar-menu ${menuIsDisplayed ? 'is-active' : ''}`}>
        <div className="navbar-start" onClick={handleClick}>
          <Link
            className="navbar-item"
            to="/"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Home
          </Link>
          <Link
            className="navbar-item"
            to="/about"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            About Us
          </Link>
        </div>
        <div className="navbar-end" onClick={handleClick}>
          <Link
            className="navbar-item"
            to="/services"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Services
          </Link>
          <Link
            className="navbar-item"
            to="/contact"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default HeaderDesktop;
