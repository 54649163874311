export default {
  address: {
    1: '2102 Avenue Z',
    2: 'Floor 1',
    3: 'Brooklyn, NY, 11235',
  },
  hours: {
    days: 'Monday to Sunday',
    hours: '10:30 AM - 7:00 PM',
  },
  contact: {
    email: 'aisoulinc@gmail.com',
    phone: '+1 (718) 872-5125',
  },
  socialMedia: {
    instagram: {
      name: '@aisoul_inc',
      link: 'https://www.instagram.com/aisoul_inc',
    },
  },
};
