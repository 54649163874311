import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import '../styles/navbar.scss';

const HeaderMobile: FC = () => {
  const [menuIsDisplayed, setMenuIsDisplayed] = useState<boolean | undefined>(
    false,
  );
  const handleClick = () => setMenuIsDisplayed(!menuIsDisplayed);
  return (
    <nav
      className="navbar has-shadow is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      style={{ minHeight: '6em' }}
    >
      <div
        className="navbar-brand"
        style={{ marginLeft: '10vw', paddingTop: '1em', paddingBottom: '1em' }}
      >
        <a
          href="/"
          style={{
            height: '6em',
            padding: '-2em',
          }}
        >
          <figure>
            <img
              src="../../aisoul.png"
              alt="logo"
              style={{
                position: 'relative',
                height: '6em',
              }}
            />
          </figure>
        </a>
        <div
          role="button"
          className={` navbar-burger burger is-vcentered ${
            menuIsDisplayed ? 'is-active' : ''
          } `}
          style={{ width: '10vw', height: '10vh', marginRight: '2vw' }}
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={`navbar-menu ${menuIsDisplayed ? 'is-active' : ''}`}>
        <div className="navbar-start" onClick={handleClick}>
          <Link
            className="navbar-item"
            to="/"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Home
          </Link>
          <Link
            className="navbar-item"
            to="/about"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            About Us
          </Link>
        </div>
        <div className="navbar-end" onClick={handleClick}>
          <Link
            className="navbar-item"
            to="/services"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Services
          </Link>
          <Link
            className="navbar-item"
            to="/contact"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default HeaderMobile;
