import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/free-brands-svg-icons/index.es.js';

import '../styles/footer.scss';
import data from '../data/footer';

const footer_title_style: string = 'title is-3 is-size-5-mobile';
const footer_text_style: string = 'is-size-5 is-size-6-mobile mb-1';

const Footer: FC = () => {
  return (
    <div>
      <div className="phantom" />
      <div
        className="footer transparent has-shadow mobile"
        style={{ backgroundColor: 'transparent', height: '100px' }}
      >
        <div className="columns">
          <div className="column">
            <h1 className={footer_title_style}>Address</h1>
            <p className={footer_text_style}>{data.address[1]}</p>
            <p className={footer_text_style}>{data.address[2]}</p>
            <p className={footer_text_style}>{data.address[3]}</p>
          </div>

          <div className="column">
            <h1 className={footer_title_style}>Hours</h1>
            <div className="block">
              <p className={footer_text_style}>{data.hours.days}</p>
              <p className={footer_text_style}>{data.hours.hours}</p>
            </div>
          </div>
          <div className="column">
            <h1 className={footer_title_style}>Contact</h1>
            <p className={footer_text_style}>Email: {data.contact.email}</p>
            <p className={footer_text_style}>Phone: {data.contact.phone}</p>
            <div>
              <div className="is-inline-flex">
                <span className="icon is-large">
                  <a
                    href={data.socialMedia.instagram.link}
                    style={{ color: 'black' }}
                  >
                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                  </a>
                </span>
                <a
                  href={data.socialMedia.instagram.link}
                  style={{ color: 'black' }}
                  className="mt-2"
                >
                  {data.socialMedia.instagram.name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
